
//to handle state
const state = {
    bus_seating:[],
    control_seats_detail:[],
    total:{
        total_pen: parseFloat(0).toFixed(2),
        total_usd: parseFloat(0).toFixed(2),
    }
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadBusSeating( context, data ) {
        context.commit('mBusSeating',data);
      },
      mLoadAddControlSeatDetail( context, data ) {
          let control_seats_detail = context.state.control_seats_detail;
          let bus_seating = context.state.bus_seating;
          let validate = true;
          for (let index = 0; index < control_seats_detail.length; index++) {
            const element = control_seats_detail[index];
            if (element.id_seating == data.id_seating && element.id_tour == data.id_tour && element.date == data.date) {
              const _index = bus_seating.map(item => item.id_seating).indexOf(data.id_seating);
              context.commit('mSeatState',{index: _index,background:'disponible'});
              context.commit('mDeleteControlSeatDetail',index);
              validate = false;
              break;
            }
          }
          
          if (validate) {
            
            const index = bus_seating.map(item => item.id_seating).indexOf(data.id_seating);
            context.commit('mSeatState',{index: index,background:'reservado'});
            context.commit('mAddControlSeatDetail',data);
            
          }
          context.dispatch('mLoadTotalDetail');
      },
      mLoadTotalDetail(context){
        let control_seats_detail = context.state.control_seats_detail;
        let total = {
          total_pen:0,
          total_usd:0,
        };
    
        for (let index = 0; index < control_seats_detail.length; index++) {
          const element = control_seats_detail[index];
          total.total_pen += parseFloat(element.price_pen);
          total.total_usd += parseFloat(element.price_usd);
        }
    
        total.total_pen = parseFloat(total.total_pen).toFixed(2);
        total.total_usd = parseFloat(total.total_usd).toFixed(2);
        context.commit('mTotalDetail',total);
      },
      mLoadResetDetail(context){
        context.commit('mResetDetail');
        context.dispatch('mLoadTotalDetail');
      }


}

//to handle mutations
const mutations = {
    mBusSeating(state,data) {
        state.bus_seating = data;
    },
    mSeatState(state,data) {
      state.bus_seating[data.index].background = data.background;
    },
    mAddControlSeatDetail(state,data) {
      state.control_seats_detail.push(data);
    },
    mDeleteControlSeatDetail(state,index) {
      state.control_seats_detail.splice(index, 1);
    },
    mTotalDetail(state,data) {
      state.total = data;
    },
    mResetDetail(state) {
      state.control_seats_detail = [];
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}