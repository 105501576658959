import Vue from "vue";
import Router from "vue-router";
const je = require("json-encrypt");

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Components

const Forms = () => import("@/views/base/Forms");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

const userGuard = (to, from, next) => {
  let user = window.localStorage.getItem("user");
  let user_permissions = window.localStorage.getItem("user_permissions");

  if (user == null || user == undefined) {
    next("/");
  } else {
    user = JSON.parse(JSON.parse(je.decrypt(user)));
    if (user.api_token.length != 60) {
      next("/");
    } else {
      let isAuthenticated = false;
      if (user_permissions == null || user_permissions == undefined) {
        next({ name: "Page404" });
      } else {
        user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
        if (user_permissions.indexOf(to.name) > -1) isAuthenticated = true;
      }
      if (!isAuthenticated) next({ name: "Page404" });
      else next();
    }
  }
};

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "Login",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/",
          name: "LoginPage",
          component: Login,
        },
      ],
    },
    {
      path: "/inicio",
      redirect: "/inicio/inicio",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "inicio",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },


    {
      path: "/usuario",
      redirect: "/usuario/listar",
      name: "Usuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserList",
          component: () => import("@/views/user/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserAdd",
          component: () => import("@/views/user/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user",
          name: "UserEdit",
          component: () => import("@/views/user/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user",
          name: "UserView",
          component: () => import("@/views/user/View"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "profile",
          name: "UserProfile",
          component: () => import("@/views/pages/Profile"),
          props: true,
        },
      ],
    },

    {
      path: "/tipo-usuario",
      redirect: "/tipo-usuario/listar",
      name: "TipoUsuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserTypeList",
          component: () => import("@/views/usertype/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserTypeAdd",
          component: () => import("@/views/usertype/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user_type",
          name: "UserTypeEdit",
          component: () => import("@/views/usertype/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user_type",
          name: "UserTypeView",
          component: () => import("@/views/usertype/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/caja",
      redirect: "/caja/listar",
      name: "Box",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BoxList",
          component: () => import("@/views/box/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BoxAdd",
          component: () => import("@/views/box/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_box",
          name: "BoxEdit",
          component: () => import("@/views/box/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_box",
          name: "BoxView",
          component: () => import("@/views/box/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/empresa",
      redirect: "/empresa/editar",
      name: "Eempresa",
      component: TheContainer,
      children: [
        {
          path: "editar",
          name: "BusinessEdit",
          component: () => import("@/views/business/Edit"),
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/comprobante",
      redirect: "/comprobante/listar",
      name: "Voucher",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "VoucherList",
          component: () => import("@/views/voucher/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "VoucherAdd",
          component: () => import("@/views/voucher/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_voucher",
          name: "VoucherEdit",
          component: () => import("@/views/voucher/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_voucher",
          name: "VoucherView",
          component: () => import("@/views/voucher/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/portada",
      redirect: "/portada/listar",
      name: "CoverPage",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CoverPageList",
          component: () => import("@/views/cover-page/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CoverPageAdd",
          component: () => import("@/views/cover-page/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_cover_page",
          name: "CoverPageEdit",
          component: () => import("@/views/cover-page/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_cover_page",
          name: "CoverPageView",
          component: () => import("@/views/cover-page/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/categoria-paquetes",
      redirect: "/categoria-paquetes/listar",
      name: "PackageCategory",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "PackageCategoryList",
          component: () => import("@/views/package-category/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "PackageCategoryAdd",
          component: () => import("@/views/package-category/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_package_category",
          name: "PackageCategoryEdit",
          component: () => import("@/views/package-category/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_package_category",
          name: "PackageCategoryView",
          component: () => import("@/views/package-category/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/paquete",
      redirect: "/paquete/listar",
      name: "Package",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "PackageList",
          component: () => import("@/views/package/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "PackageAdd",
          component: () => import("@/views/package/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_package",
          name: "PackageEdit",
          component: () => import("@/views/package/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_package",
          name: "PackageView",
          component: () => import("@/views/package/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/hotel",
      redirect: "/hotel/listar",
      name: "Hotel",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "HotelList",
          component: () => import("@/views/hotel/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "HotelAdd",
          component: () => import("@/views/hotel/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_hotel",
          name: "HotelEdit",
          component: () => import("@/views/hotel/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_hotel",
          name: "HotelView",
          component: () => import("@/views/hotel/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/testimonio",
      redirect: "/testimonio/listar",
      name: "Testimonial",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "TestimonialList",
          component: () => import("@/views/testimonial/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "TestimonialAdd",
          component: () => import("@/views/testimonial/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_testimonial",
          name: "TestimonialEdit",
          component: () => import("@/views/testimonial/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_testimonial",
          name: "TestimonialView",
          component: () => import("@/views/testimonial/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/bus",
      redirect: "/bus/listar",
      name: "Bus",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BusList",
          component: () => import("@/views/bus/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BusAdd",
          component: () => import("@/views/bus/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_bus",
          name: "BusEdit",
          component: () => import("@/views/bus/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_bus",
          name: "BusView",
          component: () => import("@/views/bus/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/ruta",
      redirect: "/ruta/listar",
      name: "Route",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "RouteList",
          component: () => import("@/views/route/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "RouteAdd",
          component: () => import("@/views/route/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_route",
          name: "RouteEdit",
          component: () => import("@/views/route/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_route",
          name: "RouteView",
          component: () => import("@/views/route/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/cliente",
      redirect: "/cliente/listar",
      name: "Client",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ClientList",
          component: () => import("@/views/client/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ClientAdd",
          component: () => import("@/views/client/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_client",
          name: "ClientEdit",
          component: () => import("@/views/client/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_client",
          name: "ClientView",
          component: () => import("@/views/client/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/cliente-web",
      redirect: "/cliente-web/listar",
      name: "Customer",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CustomerList",
          component: () => import("@/views/customer/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CustomerAdd",
          component: () => import("@/views/customer/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_customer",
          name: "CustomerEdit",
          component: () => import("@/views/customer/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_customer",
          name: "CustomerView",
          component: () => import("@/views/customer/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/control-asientos",
      redirect: "/control-asientos/listar",
      name: "ControlSeats",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ControlSeatsList",
          component: () => import("@/views/control-seats/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ControlSeatsAdd",
          component: () => import("@/views/control-seats/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_control_seats",
          name: "ControlSeatsEdit",
          component: () => import("@/views/control-seats/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_control_seats",
          name: "ControlSeatsView",
          component: () => import("@/views/control-seats/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    
    {
      path: "/tour",
      redirect: "/tour/listar",
      name: "Tour",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "TourList",
          component: () => import("@/views/tour/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "TourAdd",
          component: () => import("@/views/tour/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_tour",
          name: "TourEdit",
          component: () => import("@/views/tour/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_tour",
          name: "TourView",
          component: () => import("@/views/tour/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/pedido",
      redirect: "/pedido/listar",
      name: "Order",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "OrderList",
          component: () => import("@/views/order/List"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_order",
          name: "OrderEdit",
          component: () => import("@/views/order/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_order",
          name: "OrderView",
          component: () => import("@/views/order/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/facturacion",
      redirect: "/facturacion/listar",
      name: "Sale",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "SaleList",
          component: () => import("@/views/sale/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo-control-asiento/:id_control_seats",
          name: "SaleControlSeatAdd",
          component: () => import("@/views/sale/AddControlSeats"),
          props: true,
        },
        {
          path: "nuevo-pedido/:id_order",
          name: "SaleOrderAdd",
          component: () => import("@/views/sale/AddOrder"),
          props: true,
        },
        {
          path: "editar/:id_sale",
          name: "SaleEdit",
          component: () => import("@/views/sale/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_sale",
          name: "SaleView",
          component: () => import("@/views/sale/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/reporte",
      redirect: "/reporte/aportes",
      name: "Report",
      component: TheContainer,
      children: [
        {
          path: "Prestamos",
          name: "ReportLoanList",
          component: () => import("@/views/report/Loan"),
          beforeEnter: userGuard,
        },
        {
          path: "pago-prestamos",
          name: "ReportLoanPaymentList",
          component: () => import("@/views/report/LoanPayment"),
          beforeEnter: userGuard,
        },
        {
          path: "pago-prestamos-detallado",
          name: "ReportLoanPaymentDetailList",
          component: () => import("@/views/report/LoanPaymentDetail"),
          beforeEnter: userGuard,
        },
        {
          path: "aportes",
          name: "ReportContributionList",
          component: () => import("@/views/report/Contribution"),
          beforeEnter: userGuard,
        },
        {
          path: "aportes-detallado",
          name: "ReportContributionDetailList",
          component: () => import("@/views/report/ContributionDetail"),
          beforeEnter: userGuard,
        },
        {
          path: "movimiento",
          name: "ReportMovementList",
          component: () => import("@/views/report/Movement"),
          beforeEnter: userGuard,
        },
        {
          path: "estado-pago",
          name: "ReportPaymentStatusList",
          component: () => import("@/views/report/PaymentStatus"),
          beforeEnter: userGuard,
        },
      ],
    },

  


  ];
}
